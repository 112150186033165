//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import { useMemo }     from 'react';
import { useRef }      from 'react';
import { useCallback } from 'react';
import { useState }    from 'react';
import { useEffect }   from 'react';

import Tippy      from '@tippyjs/react';
import classNames from 'classnames';
import _          from 'lodash';

import PropTypes from '@components/PropTypes';
import Colors    from '@styles/colors.scss';

import styles from './styles.module.scss';

const propTypes = {
    color:          PropTypes.string,
    disabled:       PropTypes.bool,
    fixedWidth:     PropTypes.number,
    onLabelClicked: PropTypes.func,
    text:           PropTypes.string,
    textColor:      PropTypes.string,
};

const ShortNameLabel = ({
    text           = '',
    color          = Colors.blue,
    textColor      = Colors.white,
    onLabelClicked = _.noop,
    fixedWidth     = 0,
    disabled       = false,
}) => {
    const [shouldTooltipBeShown, setShouldTooltipBeShown] = useState(false);
    const textWrapperRef                                  = useRef();

    useEffect(() => {
        if (!textWrapperRef.current) {
            return;
        }

        setShouldTooltipBeShown(textWrapperRef.current.scrollWidth > textWrapperRef.current.clientWidth);
    }, [textWrapperRef]);

    const labelStyle        = useMemo(() => {
        const inlineStyle = {
            backgroundColor: color,
            color:           textColor,
        };

        if (fixedWidth) {
            inlineStyle.width = fixedWidth;
        }

        return inlineStyle;
    }, [color, textColor, fixedWidth]);
    const renderTextWrapper = useCallback(() => {
        const textWrapper = (
            <span ref={textWrapperRef}>
                {text}
            </span>
        );

        if (shouldTooltipBeShown) {
            return (
                <Tippy content={text}>
                    {textWrapper}
                </Tippy>
            );
        }

        return textWrapper;
    }, [text, shouldTooltipBeShown]);

    return (
        <div
            style={labelStyle}
            className={classNames(styles.shortNameLabelComponent, {
                [styles.disabled]: disabled,
            })}
            onClick={onLabelClicked}
        >
            {renderTextWrapper()}
        </div>
    );
};

ShortNameLabel.propTypes = propTypes;

export default ShortNameLabel;
