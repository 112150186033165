//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useMemo } from 'react';

import _                  from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector }    from 'react-redux';

import ContextType      from '@constants/ContextType';
import MapFilter        from '@constants/MapFilter';
import Routes           from '@constants/Routes';
import Zoom             from '@constants/Zoom';
import FilterHelper     from '@helper/FilterHelper';
import useContextLoader from '@hooks/useContextLoader';
import IconType         from '@stateless/atomic/Icon/IconType';
import NotificationType from '@stateless/atomic/NotificationItem/NotificationType';

const usePermanentNotifications = () => {
    const { t }                                   = useTranslation(null, {
        keyPrefix: 'notifications',
    });
    const filter                                  = useSelector((state) => state.map.filter) ?? [];
    const zoom                                    = useSelector((state) => state.map.zoom);
    const pathName                                = useSelector((state) => state.router.location.pathname);
    const { selectedContext }                     = useContextLoader();
    const shouldShowZoomHint                      = (
        zoom < Zoom.ShowRoutesHintZoom &&
        FilterHelper.filterIsSet(filter, MapFilter.lines) &&
        selectedContext?.type !== ContextType.route &&
        pathName === Routes.home
    );
    const shouldShowNoVisibleElementsSelectedHint = (
        _.isEmpty(filter) &&
        pathName === Routes.home
    );
    const permanentItems                          = [
        {
            icon:      IconType.warning,
            type:      NotificationType.warning,
            text:      t('noVisibleElementsSelected'),
            condition: shouldShowNoVisibleElementsSelectedHint,
        },
        {
            icon:      IconType.line,
            type:      NotificationType.warning,
            text:      t('zoomToBigForRoutes'),
            condition: shouldShowZoomHint,
        },
    ];
    const permanentNotifications                  = useMemo(
        () => _.filter(permanentItems, 'condition'),
        [
            permanentItems,
        ],
    );

    return {
        permanentNotifications,
    };
};

export default usePermanentNotifications;
