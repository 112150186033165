//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import useNotifications from '@hooks/useNotifications';
import NotificationItem from '@stateless/atomic/NotificationItem';

import styles from './styles.module.scss';

const NotificationContainer = () => {
    const { notifications } = useNotifications();

    function renderNotificationItems() {
        return _.map(notifications, (notificationItem, index) => {
            return (
                <NotificationItem
                    key={index}
                    iconType={notificationItem.icon}
                    text={notificationItem.text}
                    type={notificationItem.type}
                />
            );
        });
    }

    return (
        <div className={styles.notificationContainer}>
            <div className={styles.notificationWrapper}>
                {renderNotificationItems()}
            </div>
        </div>
    );
};

export default NotificationContainer;
