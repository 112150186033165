//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import { useMemo }     from 'react';
import { useCallback } from 'react';
import { useState }    from 'react';
import { useEffect }   from 'react';

import classNames       from 'classnames';
import _                from 'lodash';
import { MapContainer } from 'react-leaflet';
import { TileLayer }    from 'react-leaflet';
import { useSelector }  from 'react-redux';

import { useMapContext }     from '@components/MapProvider';
import MapEvents             from '@connected/MapEvents';
import MapRouteLayer         from '@connected/MapRouteLayer';
import NotificationContainer from '@connected/NotificationContainer';
import ContextType           from '@constants/ContextType';
import PolylineLevel         from '@constants/PolylineLevel';
import StopType              from '@constants/StopType';
import ContextHelper         from '@helper/ContextHelper';
import useContextApi         from '@hooks/useContextApi';
import useContextLoader      from '@hooks/useContextLoader';
import useMapAccessor        from '@hooks/useMapAccessor';
import useMapNavigation      from '@hooks/useMapNavigation';
import useRegionApi          from '@hooks/useRegionApi';
import MapControlLayer       from '@stateless/composed/MapControlLayer';
import MapLoadingLayer       from '@stateless/composed/MapLoadingLayer';
import StopMapPin            from '@stateless/composed/StopMapPin';
import StopMarkerCluster     from '@stateless/composed/StopMarkerCluster';
import Colors                from '@styles/colors.scss';

import styles from './styles.module.scss';

const TILE_THEME_URL = 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png';

const propTypes = {};

const MapLayer = () => {
    // eslint-disable-next-line no-unused-vars
    useMapNavigation();

    const { stops, routes, isFetching }             = useRegionApi();
    const { loadInContext }                         = useContextLoader();
    const zoom                                      = useSelector((state) => state.map.zoom);
    const coordinates                               = useSelector((state) => state.map.coordinates);
    const { setMapInstance }                        = useMapContext();
    const { data: contextData }                     = useContextApi();
    const [applyDynamicWidth, setApplyDynamicWidth] = useState(false);
    const { selectedContext }                       = useContextLoader();
    const { updateCoordinates }                     = useMapAccessor();
    // TODO: https://lulububu.atlassian.net/browse/BODOBIG-1119
    // const routeIds                      = _.map(routes, 'id');
    // const { data: polylinesResult }     = useFetchPolylinesQuery(
    //    {
    //        routeIds,
    //        level: PolylineLevel.low,
    //    },
    //    {
    //        skip: !routeIds.length,
    //    },
    // );
    const renderDisplayedRoutes = useCallback(() => {
        if (selectedContext) {
            let routesToDisplay       = [];
            const selectedContextType = selectedContext?.type;
            const renderLevel         = selectedContextType === ContextType.route
                ? PolylineLevel.high
                : PolylineLevel.low;

            if (selectedContextType === ContextType.route) {
                routesToDisplay = [
                    {
                        id:    selectedContext?.id,
                        color: Colors.blue,
                    },
                ];
            } else if (selectedContext?.type === ContextType.stop) {
                routesToDisplay = _.get(contextData, 'context.overview.routes', []);
            }

            return _.map(routesToDisplay, (route) => {
                return (
                    <MapRouteLayer
                        level={renderLevel}
                        key={route.id}
                        route={route}
                        ignoreMinZoomLevel={true}
                        fetchByRouteId={true}
                    />
                );
            });
        }

        return _.map(routes, (route) => {
            // TODO: https://lulububu.atlassian.net/browse/BODOBIG-1119
            // const polylineData = _.find(polylinesResult, {
            //    routeId: route.id,
            // })?.polyline;

            return (
                <MapRouteLayer
                    key={route.id}
                    route={route}
                    fetchByRouteId={true}
                />
            );
        });
    }, [selectedContext, contextData, routes]);
    const createOnMarkerClicked = (stop) => () => {
        loadInContext({
            id:   stop.id,
            type: ContextType.stop,
        });
    };
    const stopMarkers           = useMemo(() => {
        let stopsToDisplay = stops;

        if (selectedContext) {
            if (selectedContext?.type === ContextType.route) {
                stopsToDisplay = _.get(contextData, 'context.overview.stops', []);
            } else if (selectedContext?.type === ContextType.stop) {
                const stopToDisplay = _.get(contextData, 'context', null);

                if (stopToDisplay) {
                    const stopOverview = _.get(stopToDisplay, 'overview', null);
                    const stopUsage    = _.get(stopOverview, 'usage', null);

                    stopsToDisplay = [
                        {
                            id:          stopOverview?.id,
                            type:        StopType.busStop,
                            types:       stopOverview?.types,
                            usage:       stopUsage?.sums?.total,
                            coordinates: stopOverview?.coordinates,
                            active:      true,
                        },
                    ];
                }
            }
        }

        return _.map(stopsToDisplay, (stop) => {
            const { type, types } = stop;
            const stopType        = ContextHelper.getStopTypeByTypes(type, types);
            const markerComponent = (
                <StopMapPin
                    key={stop.id}
                    type={stopType}
                    passengerVolumeInPercent={stop.usage}
                    inactive={!stop.active}
                />
            );

            return {
                position:  {
                    latitude:  stop?.coordinates?.latitude ?? 0,
                    longitude: stop?.coordinates?.longitude ?? 0,
                },
                component: markerComponent,
                onClick:   createOnMarkerClicked(stop),
            };
        });
    }, [stops, selectedContext, contextData]);

    function onMapReady(data) {
        const mapInstance = data.target;

        setMapInstance(mapInstance);
        updateCoordinates(mapInstance);
    }

    // This is necessary, that the map has the correct width when it is initially rendered with an open sidebar
    useEffect(() => {
        setApplyDynamicWidth(true);
    }, []);

    return (
        <div
            className={classNames(
                styles.mapLayerComponent,
                {
                    [styles.dynamicWidth]: applyDynamicWidth,
                },
            )}
        >
            <MapContainer
                whenReady={onMapReady}
                center={[coordinates.latitude, coordinates.longitude]}
                zoom={zoom}
                zoomControl={false}
            >
                <TileLayer url={TILE_THEME_URL} />
                <StopMarkerCluster markers={stopMarkers} />
                {renderDisplayedRoutes()}
                <MapControlLayer />
                <MapLoadingLayer isLoading={isFetching} />
                <NotificationContainer />
                <MapEvents />
            </MapContainer>
        </div>
    );
};

MapLayer.propTypes = propTypes;

export default MapLayer;
