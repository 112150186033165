//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useMemo } from 'react';

import _                  from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch }    from 'react-redux';
import { useSelector }    from 'react-redux';

import usePermanentNotifications  from '@hooks/usePermanentNotifications';
import { useNotificationActions } from '@slices/notification';
import IconType                   from '@stateless/atomic/Icon/IconType';
import NotificationType           from '@stateless/atomic/NotificationItem/NotificationType';

const translationPrefix = 'notifications';

const useNotifications = () => {
    const dispatch                   = useDispatch();
    const notificationActions        = useNotificationActions(dispatch);
    const notifications              = useSelector((state) => state.notification.notifications);
    const { t, i18n }                = useTranslation(null, {
        keyPrefix: translationPrefix,
    });
    const pathName                   = useSelector((state) => state.router.location.pathname);
    const { permanentNotifications } = usePermanentNotifications();
    const notificationsToDisplay     = useMemo(() => {
        return [
            ...permanentNotifications,
            ...notifications,
        ];
    }, [pathName, notifications, permanentNotifications]);

    function removeNotification(notificationId) {
        notificationActions.removeNotification({
            notificationId,
        });
    }

    function addNotification(
        type,
        text,
        icon,
        duration = 3000,
    ) {
        const notificationId = _.uniqueId('notification_');
        let textToUse        = text;

        if (i18n.exists(`${translationPrefix}.${text}`)) {
            textToUse = t(text);
        }

        const notification = {
            id:   notificationId,
            text: textToUse,
            type,
            icon,
        };

        notificationActions.addNotification({
            notification,
        });

        _.delay(() => {
            removeNotification(notificationId);
        }, duration);
    }

    function addWarning(text, icon = IconType.warning, duration = 3000) {
        addNotification(NotificationType.warning, text, icon, duration);
    }

    function addError(text, icon = IconType.warning, duration = 3000) {
        addNotification(NotificationType.error, text, icon, duration);
    }

    function addSuccess(text, icon = IconType.success, duration = 3000) {
        addNotification(NotificationType.success, text, icon, duration);
    }

    return {
        addWarning,
        addError,
        addSuccess,
        removeNotification,
        notifications: notificationsToDisplay,
    };
};

export default useNotifications;
