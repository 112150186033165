//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import 'core-js';
import '@language/Language';
import 'moment/locale/de';

import { ConnectedRouter }     from 'connected-react-router';
import moment                  from 'moment';
import { createRoot }          from 'react-dom/client';
import { Provider }            from 'react-redux';
import { PersistGate }         from 'redux-persist/integration/react';
import { QueryParamProvider }  from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import LoadingOverlay          from '@connected/LoadingOverlay';
import MainRouter              from '@connected/MainRouter';
import StagingLoginOverlay     from '@connected/StagingLoginOverlay';
import WindowVisibilityWatcher from '@connected/WindowVisibilityWatcher';
import ImageStorage            from '@helper/ImageStorage';
import Language                from '@helper/Language';
import CacheInvalidator        from '@stateless/atomic/CacheInvalidator';
import ScreenTooSmallOverlay   from '@stateless/atomic/ScreenTooSmallOverlay';
import { history }             from '@store';
import configureStore          from '@store';

import * as serviceWorker from './serviceWorker';
import styles             from './styles.module.scss';

import '@external/sentry';
import '@store/miscellaneous/immutability-helper-extensions';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'swiper/css';
import 'tippy.js/dist/tippy.css';

ImageStorage.initializeStore();

moment.locale(Language.getDefaultLanguageShort());

const { store, persistor } = configureStore();
const container            = document.getElementById('root');
const root                 = createRoot(container);

root.render((
    <Provider store={store}>
        <PersistGate
            persistor={persistor}
            loading={null}
        >
            <CacheInvalidator />
            {/*
                If you don't want to automatically clear the browsers cache and update the
                application you can switch <CacheInvalidator /> by <CacheInvalidatorWithPrompt />
                to ask the user if he wants to update.
            */}
            <ScreenTooSmallOverlay />
            <StagingLoginOverlay>
                <LoadingOverlay />
                <WindowVisibilityWatcher />
                <ConnectedRouter history={history}>
                    <QueryParamProvider adapter={ReactRouter5Adapter}>
                        <div className={styles.appContent}>
                            <MainRouter />
                        </div>
                    </QueryParamProvider>
                </ConnectedRouter>
            </StagingLoginOverlay>
        </PersistGate>
    </Provider>
));

serviceWorker.register();
