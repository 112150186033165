//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useRef }    from 'react';
import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { useNotificationActions } from '@slices/notification';

const useNotificationClear = () => {
    const dispatch            = useDispatch();
    const NotificationActions = useNotificationActions(dispatch);
    const routePath           = useSelector((state) => state.router.location.pathname);
    const prevRoutePath       = useRef(routePath);

    useEffect(() => {
        if (prevRoutePath.current === routePath) {
            return;
        }

        prevRoutePath.current = routePath;

        NotificationActions.clearNotifications();
    }, [routePath]);

    return null;
};

export default useNotificationClear;
