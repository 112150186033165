//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import update                 from 'immutability-helper';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';

const initialState = Object.freeze({
    notifications: [],
});

const notificationSlice = createSlice({
    name:     'notification',
    initialState,
    reducers: {
        addNotification:    (state, action) => {
            const notification = _.get(action, 'payload.notification', null);

            if (
                !notification ||
                !notification.id
            ) {
                return state;
            }

            return update(state, {
                notifications: {
                    $push: [notification],
                },
            });
        },
        removeNotification: (state, action) => {
            const notificationId = _.get(action, 'payload.notificationId', null);

            if (!notificationId) {
                return state;
            }

            return update(state, {
                notifications: {
                    $set: _.filter(state.notifications, (notification) => notification.id !== notificationId),
                },
            });
        },
        clearNotifications: (state) => {
            return update(state, {
                notifications: {
                    $set: [],
                },
            });
        },
    },
});

export const NotificationActions = notificationSlice.actions;

export const NotificationReducer = notificationSlice.reducer;

export const useNotificationActions = (dispatch) => bindActionCreators(NotificationActions, dispatch);

export default notificationSlice;
