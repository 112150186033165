//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createApi }      from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import ApiUrls from '@constants/ApiUrls';
import Url     from '@helper/Url';

export const polylinesApi = createApi({
    reducerPath:       'polylinesApi',
    baseQuery:         fetchBaseQuery({
        baseUrl: Url.createEndpoint(ApiUrls.API_POLYLINES_URL),
    }),
    keepUnusedDataFor: 0,
    endpoints:         (builder) => (
        {
            fetchPolylines: builder.query({
                query: ({ routeIds, dateRange, level }) => {
                    const polylinesParams = new URLSearchParams();

                    polylinesParams.append('level', level);
                    polylinesParams.append('dateRange', dateRange);

                    routeIds.forEach((routeId) => {
                        polylinesParams.append('routeIds[]', routeId);
                    });

                    return {
                        url:    '',
                        params: polylinesParams,
                    };
                },
            }),
        }
    ),
});

export const { useFetchPolylinesQuery } = polylinesApi;

export const PolylinesApiReducer = polylinesApi.reducer;

export const PolylinesApiReducerPath = polylinesApi.reducerPath;

export const PolylinesApiMiddleware = polylinesApi.middleware;

export default polylinesApi;
