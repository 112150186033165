//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React       from 'react';
import { useMemo } from 'react';

import classNames from 'classnames';

import PropTypes        from '@components/PropTypes';
import Icon             from '@stateless/atomic/Icon';
import IconColor        from '@stateless/atomic/Icon/IconColor';
import NotificationType from '@stateless/atomic/NotificationItem/NotificationType';

import styles from './styles.module.scss';

const propTypes = {
    iconType: PropTypes.iconType,
    text:     PropTypes.string,
    type:     PropTypes.oneOfObjectValues(NotificationType),
};

const NotificationItem = ({
    iconType = null,
    text     = '',
    type     = NotificationType.info,
}) => {
    const iconColor = useMemo(() => {
        switch (type) {
            case NotificationType.error:
                return IconColor.white;
            case NotificationType.warning:
                return IconColor.black;
            case NotificationType.success:
                return IconColor.white;
            default:
                return IconColor.black;
        }
    });

    return (
        <div
            className={classNames(
                styles.notificationItem,
                {
                    [styles.error]:   type === NotificationType.error,
                    [styles.warning]: type === NotificationType.warning,
                    [styles.success]: type === NotificationType.success,
                },
            )}
        >
            <Icon
                iconType={iconType}
                iconColor={iconColor}
            />
            <span className={styles.notificationText}>
                {text}
            </span>
        </div>
    );
};

NotificationItem.propTypes = propTypes;

export default NotificationItem;
