//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import PropTypes                    from '@components/PropTypes';
import NotificationContainer        from '@connected/NotificationContainer';
import SagaComponentEvents          from '@constants/SagaComponentEvents';
import Environment                  from '@helper/Environment';
import useNotifications             from '@hooks/useNotifications';
import { useStagingAuthentication } from '@slices/stagingAuthentication';
import StatelessStagingLoginOverlay from '@stateless/composed/StagingLoginOverlay';
import { selectAuthenticated }      from '@store/selectors/stagingAuthentication';
import { selectPassword }           from '@store/selectors/stagingAuthentication';

const propTypes = {
    children: PropTypes.children,
};

const StagingLoginOverlay = ({
    children = null,
}) => {
    const dispatch                     = useDispatch();
    const stagingAuthenticationActions = useStagingAuthentication(dispatch);
    const isAuthenticated              = useSelector(selectAuthenticated);
    const password                     = useSelector(selectPassword);
    const { addError }                 = useNotifications();

    // This is a workaround, because the login error is triggered by the saga,
    // but the notification logic is implemented as a hook
    // and hooks are not usable in sagas
    function fireWrongStagingPasswordNotification() {
        addError('wrongStagingPassword');
    }

    useEffect(() => {
        window.addEventListener(SagaComponentEvents.stagingLoginError, fireWrongStagingPasswordNotification);

        return () => window.removeEventListener(SagaComponentEvents.stagingLoginError, fireWrongStagingPasswordNotification);
    }, []);

    function shouldShowStagingLoginOverlay() {
        return (
            Environment.isStaging() &&
            !Environment.isDevelopment() &&
            !Environment.isTest() &&
            !isAuthenticated
        );
    }

    if (shouldShowStagingLoginOverlay()) {
        return (
            <>
                <NotificationContainer />
                <StatelessStagingLoginOverlay
                    authenticate={stagingAuthenticationActions.authenticate}
                    passwordChanged={stagingAuthenticationActions.authenticationPasswordChanged}
                    password={password}
                />
            </>
        );
    }

    return children;
};

StagingLoginOverlay.propTypes = propTypes;

export default StagingLoginOverlay;
